import i18next from "i18next"
import { initReactI18next } from "react-i18next"

// Keep import paths as precise as possible, as general path,
// i.e. "./pages" might cause early execution of a code that requires translations,
// which will result with "undefined" if they were get via i18next.t
import { globalTranslations } from "./assets"
import { carbonSavingsTranslations } from "./pages/carbon-savings/assets"
import { contractsTranslations } from "./pages/contracts/assets"
import { customer } from "./pages/customer/assets"
import { microsite } from "./pages/microsite/assets"
import { transactions } from "./pages/transactions/assets"

const namespaces = [
	"cb",
	"cs",
	"contracts",
	"transactions",
	"microsite",
	"customer",
] as const
export const defaultNS: (typeof namespaces)[number] = "cb"
export const resources = {
	en: {
		cb: globalTranslations.en,
		cs: carbonSavingsTranslations.en,
		contracts: contractsTranslations.en,
		transactions: transactions.en,
		microsite: microsite.en,
		customer: customer.en,
	},
}

i18next.use(initReactI18next).init({
	resources,
	lng: "en",
	fallbackLng: "en",
	ns: namespaces,
	defaultNS,
	interpolation: {
		escapeValue: false, // react already safes from xss
	},
})

import {
	FundamentalContextProvider,
	ToastContainer,
	ThemeProvider,
} from "@sustainability/fundamental"
import React, { lazy } from "react"
import { useTranslation } from "react-i18next"

import "./index.css"

const CarbonBankRouter = lazy(() => import("./CarbonBankRouter"))
const AuthProvider = lazy(() => import("./providers/AuthProvider"))

const CarbonBankApp = () => {
	const { i18n } = useTranslation()

	return (
		<FundamentalContextProvider>
			<ThemeProvider>
				{i18n.isInitialized && (
					<AuthProvider>
						<CarbonBankRouter />
						<ToastContainer />
					</AuthProvider>
				)}
			</ThemeProvider>
		</FundamentalContextProvider>
	)
}

export default CarbonBankApp
